nav.MainNav.navbar.navbar-expand-md {
  flex-direction: inherit;
  padding:0;
}

.MainNav .connection-status-image {
  height: 30px;
  width: 30px;
}

.MainNav .user-dropdown {
  left: -25px;
}

.MainNav .brand-logo {
  width: 150px;
}

.MainNav .connection-status {
  width: 30px;
  height: 30px;
}

.MainNav .current-user-dropdown {
  left: -25px;
}

.MainNav .setting {
  color:white;
  margin:1em;
}

.MainNav a {
  color: white;
}

.MainNav div.dropdown-menu.show a {
  color: black;
}

/*nav.MainNav{*/
  /*flex-wrap: inherit !important;*/
/*}*/

#root > div > div > nav.MainNav.navbar.navbar-expand-md > ul {
  flex-wrap: inherit !important;
}

/*.MainNav li > a,.MainNav div.dropdown > a {*/
  /*color: white;*/
/*}*/
