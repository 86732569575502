.authenticatoinInput {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.25;
  color: #555;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
