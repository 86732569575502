

.terminal2-modal-container {
  width: 60em;
  height: 40em;
  z-index: 100;
  position: absolute;
  background-color: white;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  resize: both;
}

.terminal2-modal-hidden {
  visibility: hidden;
}

.terminal2-modal-header {
  border: 2px solid black;
  display: flex;
  flex-direction: row;
  font-size: 150%;
}

.terminal2-modal-dragbar {
  text-align: center;
  flex-grow: 1;
}

.terminal2-modal-headerbutton {
  border: 2px solid black;
  padding: 2px;
  margin: 2px;
}

.terminal2-modal-body {
  border: 2px solid black;
  flex-grow: 1;
}

.terminal2-modal-footer {
  border: 2px solid black;
  display: flex;
  flex-direction: row;
}

.terminal2-modal-footercontent {
  flex-grow: 1;
}

.terminal2-modal-resizehandle {
  border: 2px solid black;
  height: 2em;
  width: 2em;
}

