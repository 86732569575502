// Card variants

@mixin card-variant($background, $border) {
  background-color: $background;
  border-color: $border;

  .card-header,
  .card-footer {
    background-color: transparent;
  }
}

@mixin card-outline-variant($color) {
  background-color: transparent;
  border-color: $color;
}

//
// Inverse text within a card for use with dark backgrounds
//

@mixin card-inverse {
  color: rgba(255,255,255,.65);

  .card-header,
  .card-footer {
    background-color: transparent;
    border-color: rgba(255,255,255,.2);
  }
  .card-header,
  .card-footer,
  .card-title,
  .card-blockquote {
    color: #fff;
  }
  .card-link,
  .card-text,
  .card-subtitle,
  .card-blockquote .blockquote-footer {
    color: rgba(255,255,255,.65);
  }
  .card-link {
    @include hover-focus {
      color: $card-link-hover-color;
    }
  }
}
