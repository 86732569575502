canvas {
  display: block;
}

.ex-graph {
  display: block;
  margin: 0 auto 15px;
}

.ex-line-graph {
  width: 100%;
  height: 273px;
}
