.sidebar {
  .nav-toggleable-sm,
  .nav-toggleable-md {
    &.in {
      padding-bottom: 20px;
    }
  }
}

.sidebar-header {
  position: relative;
  padding-bottom: $spacer;
  @include clearfix;
}

.sidebar-toggler {
  position: absolute;
  top: 2px;
  right: 0;
  color: $sidebar-toggler-color;
  border-color: $sidebar-toggler-color;

  &:hover,
  &:focus {
    color: $sidebar-toggler-hover-color;
    background-color: $sidebar-toggler-color;
  }
}

.sidebar-brand {
  float: left;
  font-size: 18px;
  line-height: 1; // force even vertical padding
  color: $sidebar-brand-color;

  > img {
    height: 40px;

    @include media-breakpoint-up(sm) {
      height: 60px;
    }
  }

  &:hover {
    color: $sidebar-brand-hover-color;
    text-decoration: none;
  }
}

.sidebar-brand-icon {
  font-size: 40px;
}

.sidebar-form {
  position: relative;
  margin-bottom: 30px;

  .form-control {
    padding-left: 15px;
    padding-right: 15px;
  }

  .btn-link {
    position: absolute;
    top: 0;
    right: 0;
    padding: 9px 10px;
    color: lighten($gray, 25%);
    border: 0;
    line-height: 0;

    .glyphicon {
      vertical-align: text-top;
    }
  }
}

.sidebar-nav {
  @include media-breakpoint-up(lg) {
    margin-right: ($spacer * 2);
  }
}
