.nav-pills {
  > li {
    &.active > a {
      &,
      &:hover,
      &:focus {
        font-weight: $nav-pills-active-weight;
      }
    }
  }
}
