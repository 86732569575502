.report {
  padding: 0px 30px;
}

.main-container {
  background-color: white !important;
  padding-top: 20px;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #000000b5 !important;
}

.btn.link {
  color: red;
}
