.login-wrapper {
  top: 30%;
  bottom: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.login-text {
  margin: 1em;
  color: white;
  text-align: center;
}

.login-text div { 
  margin: 1em;
}

.login-wrapper-under {
  top: 90%;
  bottom: 0px;
  left: -5px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.login-wrapper .submit-spinner img.spinner {
  width: 30px;
  margin: -10px 15px 0px 0px;
}

.logo-row {
  text-align: center;
}

.logo-row img {
  width: 100%;
  margin-bottom: 10px;
}

.serverlist-server-row td {
  text-shadow: 0 0 3px white;
  color: white;
  font-size: 24pt;
  text-align: left;
}

.serverlist-container-row td {
  text-shadow: 0 0 3px white;
  color: white;
  font-size: 18pt;
  text-align: left;
  text-indent: 4em;
  border: none;
}
