select.container-selection {
  max-width: 150px;
  background: black;
  color: white;
  border: 1px solid rgba(255,255,255,0.2);
}

select.container-selection:focus {
  background: black;
  color: white;
  border: 1px solid rgba(255,255,255,0.2); 
}

select.container-selection:focus::-ms-value {
  background-color: black; 
  color: white;
}